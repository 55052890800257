import baseURL from '../services/baseURL';
import $ from 'jquery';
import './RehberEkle.css';
import React, { useContext, useState, useEffect, useRef } from 'react';
import AuthContext from '../services/AuthContext';
import { showSuccessmessage, showErrormessage } from '../services/Messages.js';
import api from '../services/Api';
import { useQuery, useMutation, QueryClient, QueryClientProvider } from "react-query";
import Select2 from 'react-select2-wrapper';
import Context from '../context/store'
import { useForm, Controller } from 'react-hook-form';


const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
	},
};

function RehberEkle() {
	const { state, dispatch } = useContext(Context);

	const [tckimlikvergino, setTckimlikvergino] = useState("");
	const [unvaniadi, setUnvaniadi] = useState("");
	const [hata, setHata] = useState(false);
	const [tuzelkisilik, setTuzelkisilik] = useState(false);
	const [tcvergino, setTcvergino] = useState('');
	const [elemanlar, setElemanlar] = useState(['SEÇİNİZ', 'USA dollar', 'British pound', 'Australian dollar', 'Japanese yen', 'Swedish krona', 'Canadian dollar', 'Swiss franc']);
	const [selectedValue, setSelectedValue] = useState(-1);
	const [selectedSehirValue, setSelectedSehirValue] = useState(null);
	const [selectedIlceValue, setSelectedIlceValue] = useState(null);
	const [ilceler, setIlceler] = useState([]);

	var contentStyle = {};
	var py5Style = {};

	const {
		register,
		control,
		handleSubmit,
		formState: { errors, isSubmitting },
		setValue,
		getValues,
		reset,
	} = useForm({});

	const { data: userData, isLoading, isError, error } = useQuery('fetchSehirler', async () => {
		const response = await api.get('/sehirler');
		return response.data;
	});

	const mutation = useMutation(async (newData) => {
		await api.post('/setrehberdetay', newData);
	});

	const onSubmit = async (data) => {
		try {
			mutation.mutate(data, {
				onSuccess: () => {
					// state.queryClient.invalidateQueries('fetchSehirler'); // Cache'i geçersiz yap

					//state.queryClient.invalidateQueries("rehberQueryClient");
					// if (isOnlyWhitespace(data.adi)) MediaStreamAudioDestinationNode() 

					document.getElementById("kt_button_1").removeAttribute("data-kt-indicator");

					showSuccessmessage('Bilgileriniz Kaydedildi...');
				},
				onError: () => {

					document.getElementById("kt_button_1").removeAttribute("data-kt-indicator");
					showErrormessage('Bilgiler kaydedilemedi. Lütfen tekrar deneyin.');
				},
			});
		} catch (err) {
			console.error(err);
		}
	};

	const [style, setStyle] = useState({
		contentStyle: {
			marginTop: "0px",
		},

		containerxxlStyle: {
			marginBottom: "20px",
		},

		py5Style: {
			paddingTop: "10px",
			paddingBottom: "0px",
		}
	});

	const context = useContext(AuthContext);

	//var sehirid = ($("#mm1")[0].selectedIndex).toString();

	//var ilceadi = $("#nn1 option:selected").text();

	//console.log(error.message) 


	//setError(error);

	const handleSelectChange = (e) => {
		setSelectedValue(e.target.value);
		// alert(e.target.value);
	};

	const handleSehirSelectChange = async (e) => {
		//setSelectedIlceValue(null);
		setSelectedSehirValue(e.target.value);

		const result = await api.get('/ilceler' + '?sehirid=' + e.target.value);

		// Dönen listenin başına özel bir öğe ekle
		const updatedIlceler = [{ text: "...", id: -1 }, ...result.data];

		setIlceler(updatedIlceler);
	};

	const handleIlceSelectChange = (e) => {

		if (e.target.value === "-1") {
			// Eğer değer -1 ise seçimleri temizle
			setSelectedIlceValue(null);
		} else {
			setSelectedIlceValue(e.target.value);
		}
	};

	useEffect(() => {

		dispatch({ type: "SET_CURRENTPAGENAME", payload: "Rehber" })

		dispatch({ type: "SET_CURRENTSUBPAGENAME", payload: "Ekle" })

		dispatch({ type: "SET_CURRENTRECCOUNT", payload: -1 })

		if (window.innerWidth < 990) {

			setStyle({
				contentStyle: {
					marginTop: "0px",
				},

				containerxxlStyle: {
					marginBottom: "100px",
				},

				py5Style: {
					paddingTop: "0px",
					paddingBottom: "0px",
				},
			})

		}
		else {

			setStyle({
				contentStyle: {
					marginTop: "0px",
				},

				containerxxlStyle: {
					marginBottom: "100px",
				},

				py5Style: {
					paddingTop: "10px",
					paddingBottom: "0px",
				},
			})

		}

		return () => {

		}
	}, []);


	if (isLoading) {
		return (

			<div className="child">

				<div id="loadericondiv2" style={{ display: 'block', color: 'lightgray', fontSize: '50px', marginTop: '0px' }} className="float-left">
					<i id="loadericon" className="fas fa-sync fa-spin"></i>
				</div>

			</div>

		);
	}

	if (isError) {
		return (
			<div className="error-container">
				{error.response.status === 402 ? 'Bu bölümü görüntüleme yetkiniz yok.' : "Bir hata oluştu: " + (error.response.status)};
			</div>
		);
	}

	return (

		<div className="RehberEkle" style={{ marginTop: "-20px", marginBottom: "130px" }}>

			<div className="content d-flex flex-column flex-column-fluid" style={style.contentStyle} id="kt_content">


				<div id="kt_content_container" className="container-xxl" style={style.containerxxlStyle}>

					<form id="RehberEkleForm" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
						<div className="row gy-0 mb-6 mb-xl-12">

							<div className="col-md-12">


							</div>
						</div>

						<div id="info" className="card  card-md-stretch me-xl-3 mb-md-0 mb-6">

							<div className="card-header">

								<div className="card-title">
									<h4 className="fw-bolder">Genel Bilgiler</h4>
								</div>

								<div className="card-toolbar">

									<button
										id="kt_button_1"
										type="submit"
										className="btn btn-primary btn-sm"
										disabled={isSubmitting}
									>
										{isSubmitting ? (
											<>
												Kaydet
												<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
											</>
										) : (
											'Kaydet'
										)}
									</button>

								</div>

							</div>

							<div className="row gy-0 mb-6 mb-xl-12">
								<div className="col-md-6">

									{/* -----------------------------------------  */}
									<div className="card-body">

										<div className="d-flex flex-wrap" style={style.py5Style}>

											<div className="form-group mdv" style={{ width: "100%" }}>
												<label for="adilabel" className="required fs-6 fw-bold mb-2 control-label">Adı Soyadı / Ünvanı</label>

												<label className="error" for="adifield"></label>

												<input
													id="id_adiunvani"
													type="text"
													defaultValue=""
													className={`form-control form-control-solid ${errors.adiunvani ? 'is-invalid' : ''}`}
													{...register('adiunvani', {
														required: 'Bu alan zorunludur.',
														validate: (value) => !!value.trim() || 'Bu alan zorunludur.',
														minLength: { value: 3, message: 'Bu alanda en az 2 karakter olmalıdır.' },
													})}
													onBlur={(e) => {
														if (e.target.value != e.target.value.trim()) {
															const trimmedValue = e.target.value.trim();
															setValue('adiunvani', trimmedValue, { shouldValidate: true });
															reset({
																...getValues(),
																adiunvani: trimmedValue,
															});
														}
													}}
													onKeyDown={(e) => {
														if (e.key === 'Enter') {
															if (e.target.value != e.target.value.trim()) {
																const trimmedValue = e.target.value.trim();
																setValue('adiunvani', trimmedValue, { shouldValidate: true });
																reset({
																	...getValues(),
																	adiunvani: trimmedValue,
																});
															}
														}
													}}
												/>
												{errors.adiunvani && (
													<div className="invalid-feedback">{errors.adiunvani.message}</div>
												)}
											</div>

											<div className="form-group mdv" style={{ width: "100%" }}>
												<label for="soyadilabel" className="required fs-6 fw-bold mb-2 control-label">Adres</label>
												<label className="error" for="soyadifield"></label>
												<input
													id="id_adres"
													type="text"

													className={`form-control form-control-solid ${errors.adres ? 'is-invalid' : ''}`}
													{...register('adres', {
														required: 'Bu alan zorunludur.',
														validate: (value) => !!value.trim() || 'Bu alan zorunludur.',
														minLength: { value: 3, message: 'Bu alanda en az 2 karakter olmalıdır.' },
													})}
													onBlur={(e) => {
														if (e.target.value != e.target.value.trim()) {
															const trimmedValue = e.target.value.trim();
															setValue('adres', trimmedValue, { shouldValidate: true });
															reset({
																...getValues(),
																adres: trimmedValue,
															});
														}
													}}
													onKeyDown={(e) => {
														if (e.key === 'Enter') {
															if (e.target.value != e.target.value.trim()) {
																const trimmedValue = e.target.value.trim();
																setValue('adres', trimmedValue, { shouldValidate: true });
																reset({
																	...getValues(),
																	adres: trimmedValue,
																});
															}
														}
													}}
												/>
												{errors.adres && (
													<div className="invalid-feedback">{errors.adres.message}</div>
												)}
											</div>

										</div>
									</div>

									{/* -----------------------------------------  */}

								</div>

								<div className="col-md-6">

									{/* -----------------------------------------  */}
									<div className="card-body">

										<div className="d-flex flex-wrap" style={style.py5Style}>

											<div className="form-group mdv" style={{ width: "100%" }}>
												<label for="adilabel" className="required fs-6 fw-bold mb-2 control-label">Şehir</label>
												<Controller
													name="sehir"
													control={control}
													defaultValue="" // Varsayılan boş değer
													rules={{
														required: "Bu alan zorunludur", // Doğrulama mesajı
													}}
													render={({ field }) => (
														<>
															<Select2
																id="sid3"
																className="form-control form-control-solid"
																style={{ width: "100%" }}
																data={userData}
																value={field.value} // Form değerini bağlama
																options={{
																	placeholder: "Seçiniz",
																	language: {
																		noResults: "Sonuç bulunamadı",
																	},
																}}
																onChange={(e) => {
																	field.onChange(e.target.value); // React Hook Form'a değeri aktar
																	handleSehirSelectChange(e);
																}}
															/>
															{errors.sehir && (
																<span className="text-danger">{errors.sehir.message}</span>
															)}
														</>
													)}
												/>
											</div>

											<div className="form-group mdv" style={{ width: "100%" }}>
												<label for="adilabel" className="fs-6 fw-bold mb-2 control-label">İlçe</label>
												<Select2 id="sid4"
													className="form-control form-control-solid"
													style={{ width: "100%", height: "500px" }}
													multiple={false}
													data={ilceler}
													//defaultValue={1}
													value={selectedIlceValue}
													options={{
														//tags: true, // Olmayan seçeneği ekleme
														placeholder: 'Seçiniz',
														//allowClear: true, // Clear seçeneği ekler
														// placeholder: 'Seçiniz',
														//minimumResultsForSearch: -1, 
														language: {
															noResults: '',
														}
													}}
													onChange={handleIlceSelectChange}
												/>
											</div>

										</div>
									</div>

									{/* -----------------------------------------  */}

								</div>
							</div>

						</div>

						<div id="info" className="card  card-md-stretch me-xl-3 mb-md-0 mb-6" style={{ marginTop: "20px" }}>

							<div className="card-header">

								<div className="card-title">
									<h4 className="fw-bolder">Vergi Bilgileri</h4>
								</div>

							</div>

							<div className="row gy-0 mb-6 mb-xl-12">
								<div className="col-md-6">

									{/* -----------------------------------------  */}
									<div className="card-body">

										<div className="d-flex flex-wrap" style={style.py5Style}>

											<div className="form-group mdv" style={{ width: "100%" }}>
												<label for="adilabel" className="required fs-6 fw-bold mb-2 control-label">Statü</label>

												<Controller
													name="statu"
													control={control}
													defaultValue="" // Varsayılan boş değer
													rules={{
														required: "Bu alan zorunludur", // Doğrulama mesajı
													}}
													render={({ field }) => (
														<>
															<Select2
																id="sid"
																className="form-control form-control-solid"
																style={{ width: "100%" }}
																data={[
																	{ text: 'Gerçek Kişi', id: 1 },
																	{ text: 'Tüzel Kişi', id: 2 },
																]}
																value={field.value} // Form değerini bağlama
																options={{
																	placeholder: "Seçiniz",
																	language: {
																		noResults: "Sonuç bulunamadı",
																	},
																}}
																onChange={(e) => {
																	field.onChange(e.target.value); // React Hook Form'a değeri aktar
																	handleSelectChange(e);
																}}
															/>
															{errors.sehir && (
																<span className="text-danger">{errors.sehir.message}</span>
															)}
														</>
													)}
												/>

											</div>

											<div class="d-flex flex-stack">

											</div>
										</div>
									</div>

									{/* -----------------------------------------  */}

								</div>

								<div className="col-md-6">

									{/* -----------------------------------------  */}
									<div className="card-body" >

										<div className="d-flex flex-wrap" style={style.py5Style}>

											<div className="form-group mdv" style={{ width: selectedValue == 2 ? '100%' : '100%', marginTop: "10px" }}>
												<label for="adilabel" className="required fs-6 fw-bold mb-2 control-label">{selectedValue == 2 ? "Vergi No" : "TC Kimlik No"}</label>
												<label className="error" for="adifield"></label>

												<input
													id="id_tckimlikvergino"
													type="number"
													maxlength={parseInt(selectedValue, 10) === 2 ? "9" : "10"}
													defaultValue=""
													className={`form-control form-control-solid ${errors.tckimlikvergino ? 'is-invalid' : ''}`}
													key={selectedValue}
													{...register('tckimlikvergino', {
														required: 'Bu alan zorunludur.',
														minLength: {
															value: parseInt(selectedValue, 10) === 2 ? 9 : 10,
															message: 'Bu alanda en az ' + (parseInt(selectedValue, 10) === 2 ? 9 : 10) + ' karakter olmalıdır.',
														},
														maxLength: {
															value: parseInt(selectedValue, 10) === 2 ? 9 : 10,
															message: 'Bu alanda en fazla ' + (parseInt(selectedValue, 10) === 2 ? 9 : 10) + ' karakter olmalıdır.',
														}
													})}
													onInput={(e) => {
														e.target.value = e.target.value.replace(/[^0-9]/g, ''); // Yalnızca rakamları kabul et
														if (e.target.value.length > 10) {
															e.target.value = e.target.value.slice(0, 10); // Maksimum 5 karakter
														}
													}}
													onBlur={(e) => {
														if (e.target.value != e.target.value.trim()) {
															const trimmedValue = e.target.value.trim();
															setValue('tckimlikvergino', trimmedValue, { shouldValidate: true });
															reset({
																...getValues(),
																tckimlikvergino: trimmedValue,
															});
														}
													}}
													onKeyDown={(e) => {
														if (e.key === 'Enter') {
															if (e.target.value != e.target.value.trim()) {
																const trimmedValue = e.target.value.trim();
																setValue('tckimlikvergino', trimmedValue, { shouldValidate: true });
																reset({
																	...getValues(),
																	tckimlikvergino: trimmedValue,
																});
															}
														}
													}}
												/>
												{errors.tckimlikvergino && (
													<div className="invalid-feedback">{errors.tckimlikvergino.message}</div>
												)}

											</div>

										</div>

									</div>



									{/* -----------------------------------------  */}



								</div>

								<div className="col-md-12">


									<div className="d-flex flex-wrap" style={style.py5Style}>

										{selectedValue == 2 ?
											<div className="form-group mdv" style={{ width: "100%" }}>
												<label for="soyadilabel" className="required fs-6 fw-bold mb-2 control-label"> &nbsp;Vergi Dairesi</label>
												<label className="error" for="soyadifield"></label>
												<input id="id_soyad" name="soyad" className="form-control form-control-solid" spellcheck="false" type="text"
													maxlength="100" defaultValue={""} placeholder="" />
											</div> : null
										}

									</div>
								</div>
							</div>


						</div>




					</form>

				</div>








			</div>


		</div>
	);
}


export default RehberEkle;