import React, { useContext, useState, useEffect } from 'react';
import './RehberDuzenle.css';
import { NavLink } from 'react-router-dom';
import toastr from 'toastr';
import request from '../services/Request';
//import AuthContext from '../services/AuthContext';
import $ from 'jquery';
import Modal from 'react-modal';
import baseURL from '../services/baseURL';
//import CacheItem from '../services/CacheItem';

import { showSuccessmessage, showErrormessage } from '../services/Messages.js';
import api from '../services/Api';
import { useQuery, useMutation } from 'react-query';
import Context from '../context/store'
import { useForm } from 'react-hook-form';

const options = {
  placeholder: "Seçiniz",
  language: {
    noResults: function () {
      return "Sonuç bulunamadı...";
    }
  },
};

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
  },
  overlay: {
    backgroundColor: 'rgba(255, 255, 255, 0.75)'
  },
};
// datayı state e al render anında map et
//      this.setState({ loading: false });
//class RehberDuzenle extends React.Component {

//static contextType = AuthContext;

// state = { sehirler: [], ilceler: [] };

function RehberDuzenle(props) {
  const { state, dispatch } = useContext(Context);

  const [showModal, setShowModal] = useState(false);
  const [html, setHtml] = useState(null);
  const [elemanlar, setElemanlar] = useState(['GERÇEK KİŞİ', 'TÜZEL KİŞİ']);
  const [unvaniadi, setUnvaniadi] = useState('');
  const [musno, setMusno] = useState('');
  const [tcvergino, setTcvergino] = useState('');
  const [tuzelkisilik, setTuzelkisilik] = useState(false);
  const [sehir, setSehir] = useState('');
  const [ilce, setIlce] = useState('');
  const [adres, setAdres] = useState('');
  const [telefon, setTelefon] = useState('');
  const [faks, setFaks] = useState('');
  const [gsm, setGsm] = useState('');
  const [email, setEmail] = useState('');
  const [tipi, setTipi] = useState('');
  const [sehirler, setSehirler] = useState([]);
  const [ilceler, setIlceler] = useState([]);
  const [hata, setHata] = useState(false);
  const [style, setStyle] = useState({});
  const [errorStatus, setErrorStatus] = useState('');

  //const context = useContext(AuthContext);

  const {
		register,
		handleSubmit,
		formState: { errors, isSubmitting, isDirty },
		setValue,
		getValues,
		reset,
	  } = useForm({});
	  
    const { data, isLoading, isError, error } = useQuery(['fetchRehberDuzenleData', props.id], async () => {
      const response = await api.get('/getrehberdetay'+ '?id=' + props.id);
      return response.data;
    });

    const mutation = useMutation(async (updatedData) => {
      await api.post('/setrehberdetay', updatedData);
    });

    
      const onSubmit = async (data) => {
      if (!isDirty) {
        showErrormessage('Hiçbir değişiklik yapmadınız.');
        return;
      }
      try {
        mutation.mutate(data, {
          onSuccess: () => {
           // localStorage.setItem('name', `${data.adi} ${data.soyadi}`);
            //dispatch({ type: 'SET_NAME', payload: `${data.adi} ${data.soyadi}` });
            state.queryClient.invalidateQueries(['fetchRehberDuzenleData', props.id]); // Cache'i geçersiz yap
            showSuccessmessage('Bilgileriniz Kaydedildi...');
          },
          onError: () => {
            showErrormessage('Bilgiler kaydedilemedi. Lütfen tekrar deneyin.');
          },
        });
      } catch (err) {
        console.error(err);
      }
    };

    
  //return api.post(`/setrehberdetay`, { 'id': props.id, 'tckimlikvergino': tcvergino, 'tuzelkisilik': tuzelkisilik, 'unvaniadi': unvaniadi, 'sehirid': sehirid, 'ilceadi': ilceadi });
  
  const handleOpenModal = () => {
    setShowModal(true);
  }

  const handleCloseModal = () => {
    setShowModal(false);
  }

  const handleChangeUnvaniAdi = (event) => {
    setUnvaniadi(event.target.value.toLocaleUpperCase('TR'));
  }

  const handleChangeTcVergino = (event) => {
    setTcvergino(event.target.value);
  }
  String.prototype.turkishToUpper = function () {
    var string = this;
    var letters = { "i": "İ", "ş": "Ş", "ğ": "Ğ", "ü": "Ü", "ö": "Ö", "ç": "Ç", "ı": "I" };
    string = string.replace(/(([iışğüçö]))/g, function (letter) { return letters[letter]; })
    return string.toUpperCase();
  }

  String.prototype.turkishToLower = function () {
    var string = this;
    var letters = { "İ": "i", "I": "ı", "Ş": "ş", "Ğ": "ğ", "Ü": "ü", "Ö": "ö", "Ç": "ç" };
    string = string.replace(/(([İIŞĞÜÇÖ]))/g, function (letter) { return letters[letter]; })
    return string.toLowerCase();
  }
  const ilkHarfiBuyukYap = (input) => {
    if (input == null) return "";
    return input.turkishToLower().replace(/(?:^|\s)\S/g, function (a) { return a.turkishToUpper(); });
  }
  const TelefonFormat = (value) => {
    var st = value;
    var data = value;
    if (data != null && data.length == 10) st = '(' + data.substr(0, 3) + ') ' + data.substr(3, 3) + ' ' + data.substr(6, 4);
    if (data != null && data.length == 7) st = '(242) ' + data.substr(0, 3) + ' ' + data.substr(3, 7);
    return st;
  }

  const Sil = () => {

  }

  const IlceYukle = (sehir) => {

    request('POST', '/ilcedoldur',
      { "sehiradi": sehir },
      IlceDoldur_Basarili, IlceDoldur_Basarisiz,
      '');

  }

  const IlceDoldur_Basarili = (response) => {

    var ilceler = response.data;

    setIlceler(ilceler);
    setIlce("...");
  }


  const IlceDoldur_Basarisiz = (status) => {

  }

  // const RehberDuzenle_Basarili = (response) => {
  //   var rehber = response.data;

  //     setSehirler(rehber.sehirler);
  //     setIlceler(rehber.ilceler);     
  //     setUnvaniadi(rehber.unvaniadi);
  //     setTuzelkisilik(rehber.tuzelkisilik);
  //     setMusno(rehber.musno);
  //     setTcvergino(rehber.tcvergino);
  //     setAdres(rehber.adres);
  //     setEmail(rehber.email);
  //     setTipi(rehber.tipi); 
  //     setSehir(rehber.sehir);
  //     setIlce(rehber.ilce);
  //     setLoading(false);
  //       if (tuzelkisilik === true) {

  //         $("#id_tcvergino").attr('maxlength', '10');
  //         $("#id_tcvergino").attr('minlength', '10');

  //       }
  //       else {
  //         $("#id_tcvergino").attr('maxlength', '11');
  //         $("#id_tcvergino").attr('minlength', '11');

  //       };

  //       $('#mm').on("select2:select", function (e) {
  //         IlceYukle(e.params.data.text);
  //       });

  // }


  // const RehberDuzenle_Basarili_Offline = (response) => {

  //   var rehber = context.DATA;

  //     setSehirler(rehber.sehirler);
  //     setIlceler(rehber.ilceler);     
  //     setUnvaniadi(rehber.unvaniadi);
  //     setTuzelkisilik(rehber.tuzelkisilik);
  //     setMusno(rehber.musno);
  //     setTcvergino(rehber.tcvergino);
  //     setAdres(rehber.adres);
  //     setEmail(rehber.email);
  //     setTipi(rehber.tipi); 
  //     setSehir(rehber.sehir);
  //     setIlce(rehber.ilce);
  //     setLoading(false);
  //       if (tuzelkisilik === true) {

  //         $("#id_tcvergino").attr('maxlength', '10');
  //         $("#id_tcvergino").attr('minlength', '10');

  //       }
  //       else {
  //         $("#id_tcvergino").attr('maxlength', '11');
  //         $("#id_tcvergino").attr('minlength', '11');

  //       };

  //       $('#mm').on("select2:select", function (e) {
  //         IlceYukle(e.params.data.text);
  //       });

  // }



  const RehberDuzenle_Basarili = (response) => {
    var rehber = response.data;
    var tel = rehber.telefon;
    if (tel != null && tel.length == 10) tel = '(' + tel.substr(0, 3) + ') ' + tel.substr(3, 3) + ' ' + tel.substr(6, 4);
    if (tel != null && tel.length == 7) tel = '(242) ' + tel.substr(0, 3) + ' ' + tel.substr(3, 7);
    var faks = rehber.faks;
    if (faks != null && faks.length == 10) faks = '(' + faks.substr(0, 3) + ') ' + faks.substr(3, 3) + ' ' + faks.substr(6, 4);
    if (faks != null && faks.length == 7) faks = '(242) ' + faks.substr(0, 3) + ' ' + faks.substr(3, 7);
    var gsm = rehber.gsm;
    if (gsm != null && gsm.length == 10) gsm = '(' + gsm.substr(0, 3) + ') ' + gsm.substr(3, 3) + ' ' + gsm.substr(6, 4);
    if (gsm != null && gsm.length == 7) gsm = '(242) ' + gsm.substr(0, 3) + ' ' + gsm.substr(3, 7);
   // setLoading(false);
    setUnvaniadi(rehber.unvaniadi);
    setMusno(rehber.musno);
    setTcvergino(rehber.tcvergino);
    setTuzelkisilik(rehber.tuzelkisilik);
    setSehir(rehber.sehir);
    setIlce(rehber.ilce);
    setAdres(rehber.adres);
    setTelefon(tel);
    setFaks(faks);
    setGsm(gsm);
    setEmail(rehber.email);
    setTipi(rehber.tipi);
    //setBasvurular(JSON.parse(rehber.basvurular));
  }


  useEffect(() => {
    const updateStyles = () => {
      if (window.innerWidth < 990) {
        setStyle({
          contentStyle: {
            marginTop: "0px",
          },
          containerxxlStyle: {
            marginBottom: "20px",
          },
          py5Style: {
            paddingTop: "0px",
            paddingBottom: "0px",
          },
          divStyle: {
            width: '100%',
          }
        });
      } else {
        setStyle({
          contentStyle: {
            marginTop: "20px",
          },
          containerxxlStyle: {
            marginBottom: "20px",
          },
          py5Style: {
            paddingTop: "10px",
            paddingBottom: "0px",
          },
        });
      }
    };

    window.addEventListener('resize', updateStyles);

    updateStyles();

    return () => {
      window.removeEventListener('resize', updateStyles);
    };
  }, []);

  useEffect(() => {

    dispatch({ type: "SET_CURRENTPAGENAME", payload: "Rehber" })

    dispatch({ type: "SET_CURRENTSUBPAGENAME", payload: "Düzenle" })

    dispatch({ type: "SET_CURRENTRECCOUNT", payload: -1 })
  

 //state.queryClient.invalidateQueries(['fetchRehberDuzenleData', props.id]); // Cache'i geçersiz yap

    var reqdata = { 'id': props.id };

    state.rehberdetay_postparam = { 'id': props.id };


    //request('GET', '/getrehberdetay', state.rehberdetay_postparam, RehberDuzenle_Basarili, RehberDuzenle_Basarisiz, '');


    return () => {
      //unmount
    }

  }, []);


  const formPreventDefault = (e) => {
    e.preventDefault();
  }

  const kisilikSelected = () => {

    if ($('#zz1').val() == "GERÇEK KİŞİ") {
      setTuzelkisilik(false);
      setTcvergino('');
    }
    else {
      setTuzelkisilik(true);
      setTcvergino('');
    }

    $("#id_tcvergino:text").val("");
    if ($('#zz1').val() == "GERÇEK KİŞİ") {

      // $("label[for*='tcverginolabel']").html("TC Kimlik No");
      //$("#id_tcvergino").attr("placeholder", "Şahıs vergi mükellefi ise doldurulmalıdır !");

      //   $("label[for*='ilgilikisilabel']").html("İrtibat Kurulacak Kişi");
      $("#id_tcvergino").attr('maxlength', '11');
      $("#id_tcvergino").attr('minlength', '11');

      //$("#id_tcvergino").rules("remove", "minlength");
      // $("#id_tcvergino").rules("add", { minlength: 11 });
      // $("label[for*='firmalabel']").html("Üretici Adı Soyadı");
      //  $("#vdairefield").rules("remove", "required");
      //  $("#yetkilifield").rules("remove", "required");                
    }

    if ($('#zz1').val() == "TÜZEL KİŞİ") {

      // $("label[for*='tcverginolabel']").html("Vergi No");
      //$("#id_tcvergino").attr("placeholder", "");
      //  $("label[for*='vdairelabel']").html("İrtibat Kurulacak Kişi");
      //  $("label[for*='ilgilikisilabel']").html("Vergi Dairesi");
      $("#id_tcvergino").attr('maxlength', '10');
      $("#id_tcvergino").attr('minlength', '10');
      // $("#id_tcvergino").val("");
      // $("#id_tcvergino").rules("remove", "minlength");
      //$("#id_tcvergino").rules("add", { minlength: 10 });
      // $("label[for*='firmalabel']").html("Firma Ünvanı");               
      // $("#vdairefield").rules("add", "required");
      //  $("#yetkilifield").rules("add", "required");
    }
  }

  const sehirSelected = () => {
    IlceYukle($('#mm1').val());
    setSehir($('#mm1').val());
  }

  const ilceSelected = () => {
    setIlce($('#nn1').val());
  }

  const Template = () => {

    return (

      !isLoading ? <div className="RehberDuzenle">


        <section className="content" style={style.contentStyle}>

          {/* hb */}
          <div className="post d-flex flex-column-fluid" id="kt_post">
            <div id="kt_content_container" className="container-xxl">

              <div className="row gy-0 mb-6 mb-xl-12">
                <div className="col-md-12">
                  <div id="info" className="card  me-xl-3 mb-md-0 mb-6" style={{ paddingBottom: '50px' }}>
                    <div className="card-header">

                      <div className="card-title">
                        <h4 className="fw-bolder">Müşteri</h4>
                      </div>

                      <div className="card-toolbar">

                        <button id="kt_button_1" className="btn btn-primary btn-sm">
                          <span className="indicator-label">
                            Kaydet
                          </span>
                          <span className="indicator-progress">
                            Kaydet<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        </button>

                      </div>

                    </div>

                    <div className="card-body">


                      <div className="d-flex flex-wrap py-5">
                      <form id="myForm" onSubmit={handleSubmit(onSubmit)} autoComplete="off">

                          <div className="form-group mdv" style={{ width: '100%', paddingBottom: '10px' }}>
                            <label htmlFor="soyadilabel" className="required fs-6 fw-bold mb-2 control-label">Adı Soyadı / Ünvanı</label>
                            <label className="error" htmlFor="soyadifield"></label>
                            <input id="id_unvaniadi" name="ad" defaultValue={ilkHarfiBuyukYap(data.UNVANIADI)} onChange={e => data.UNVANIADI = e.target.value} className="form-control form-control-solid" type="text" spellcheck="false" required="" maxLength="100" placeholder=""></input>

                          </div>  


                          <div className="form-group mdv" style={{ width: '100%', paddingBottom: '10px' }}>
                            <label htmlFor="kullanicilabel" className="control-label">Rehber No</label>
                            <label className="error" htmlFor="kullanicifield"></label>
                            <input id="id_rehberid" name="rehberid" defaultValue={props.id} onChange={e => props.id = e.target.value} className="form-control form-control-solid" type="text" spellcheck="false" required="" maxLength="100" placeholder="" readOnly="readOnly" ></input>

                          </div>

                          <div className="form-group mdv" style={{ width: '100%', paddingBottom: '10px' }}>
                            <label htmlFor="rehberlabel" className="control-label">Müşteri No</label>
                            <label className="error" htmlFor="rehberfield"></label>
                            <input id="id_uyeno" name="uyeno" defaultValue={data.MUSTERINO} onChange={e => data.MUSTERINO = e.target.value} className="form-control form-control-solid" type="text" spellcheck="false" required="" maxLength="60" placeholder="" readOnly="readOnly" ></input>

                          </div>

                          <div className="form-group" style={{ paddingBottom: '10px' }}>
                            <label htmlFor="tcverginolabel" className="control-label">{tuzelkisilik ? 'Vergi No' : 'TC Kimlik No'}</label>
                            <label className="error" htmlFor="tcverginofield"></label>
                            <input id="id_tcvergino" onChange={handleChangeTcVergino} name="tcvergino" className="form-control" type="text" required="required"
                              minlength="11" maxLength="11" value={tcvergino || ""} placeholder=""
                              title="Bu bölümde bir sayı bulunmalıdır..." pattern="\d*" />
                          </div>
                          <p></p>

                        </form>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>
          {/* h */}


        </section>


      </div> : <div className="card card-outline cardm" style={{ width: "98%", height: "560px" }}>
        <div className="child">
          {errorStatus != "402" ?
            <div id="loadericondiv2" style={{ display: 'block', color: 'lightgray', fontSize: '50px', marginTop: '0px' }} className="float-left">
              <i id="loadericon" className="fas fa-sync fa-spin"></i>
            </div> : <div style={{ display: 'block', color: 'lightgray', fontSize: '20px', marginTop: '0px', marginLeft: '-150px' }} className="float-left">
              <i id="loadericon">Bu bölümü görüntüleme yetkiniz yok !</i>
            </div>}

        </div>
      </div>

    );

  }

  // if (!navigator.onLine) 
  // return <div>Hata:İnternet bağlantısı yok...</div>;


  //context.setLoader(false);
  return Template();

}

export default RehberDuzenle;



