import React, { useContext, useEffect, useState } from 'react';
import { useQuery, queryClient, useMutation } from 'react-query';
import { showSuccessmessage, showErrormessage } from '../services/Messages.js';
import api from '../services/Api';
import Context from '../context/store';
import { useForm } from 'react-hook-form';

function HesabimDegistirCard() {
  const { state, dispatch } = useContext(Context);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isDirty },
    setValue,
    getValues,
    reset,
  } = useForm({});

  const { data: userData, isLoading, isError, error } = useQuery('fetchUser', async () => {
    const response = await api.get('/bilgilerim');
    return response.data;
  });

  const mutation = useMutation(async (updatedData) => {
    await api.post('/bilgilerim', updatedData);
  });

  const onSubmit = async (data) => {
    if (!isDirty) {
      showErrormessage('Hiçbir değişiklik yapmadınız.');
      return;
    }
    try {
      mutation.mutate(data, {
        onSuccess: () => {
          localStorage.setItem('name', `${data.adi} ${data.soyadi}`);
          dispatch({ type: 'SET_NAME', payload: `${data.adi} ${data.soyadi}` });
          state.queryClient.invalidateQueries('fetchUser'); // Cache'i geçersiz yap
          showSuccessmessage('Bilgileriniz Kaydedildi...');
        },
        onError: () => {
          showErrormessage('Bilgiler kaydedilemedi. Lütfen tekrar deneyin.');
        },
      });
    } catch (err) {
      console.error(err);
    }
  };

  if (isLoading) {
    return (

      <div className="child">

        <div id="loadericondiv2" style={{ display: 'block', color: 'lightgray', fontSize: '50px', marginTop: '0px' }} className="float-left">
          <i id="loadericon" className="fas fa-sync fa-spin"></i>
        </div>

      </div>

    );
  }


  if (isError) {
    return (
      <div className="error-container">
        {error.response.status === 402 ? 'Bu bölümü görüntüleme yetkiniz yok.' : "Bir hata oluştu: " + (error.response.status)};
      </div>
    );
  }

  return (

    <div id="info" className="card">

      <form id="myForm" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className="card-header">
          <div className="card-title">
            <h4 className="fw-bolder">Bilgilerim</h4>
          </div>
          <div className="card-toolbar">

            <button
              id="kt_button_1"
              type="submit"
              className="btn btn-primary btn-sm"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <>
                  Kaydet
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </>
              ) : (
                'Kaydet'
              )}
            </button>


          </div>
        </div>

        <div className="card-body">
          <div className="d-flex flex-wrap py-5">
            <div className="form-group mdv" style={{ width: '100%' }}>
              <label htmlFor="id_ad" className="required fs-6 fw-bold mb-2 control-label">
                Adı
              </label>

              <input
                id="id_ad"
                type="text"
                defaultValue={userData.adi}
                className={`form-control form-control-solid ${errors.adi ? 'is-invalid' : ''}`}
                {...register('adi', {
                  required: 'Bu alan zorunludur.',
                  validate: (value) => !!value.trim() || 'Bu alan zorunludur.',
                  minLength: { value: 3, message: 'Bu alanda en az 2 karakter olmalıdır.' },
                })}
                onBlur={(e) => {
                  if (e.target.value != e.target.value.trim()) {
                    const trimmedValue = e.target.value.trim();
                    setValue('adi', trimmedValue, { shouldValidate: true });
                    reset({
                      ...getValues(),
                      adi: trimmedValue,
                    });
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    if (e.target.value != e.target.value.trim()) {
                      const trimmedValue = e.target.value.trim();
                      setValue('adi', trimmedValue, { shouldValidate: true });
                      reset({
                        ...getValues(),
                        adi: trimmedValue,
                      });
                    }
                  }
                }}
              />
              {errors.adi && (
                <div className="invalid-feedback">{errors.adi.message}</div>
              )}

            </div>

            <div className="form-group mdv" style={{ width: '100%' }}>
              <label htmlFor="id_soyad" className="required fs-6 fw-bold mb-2 control-label">
                Soyadı
              </label>

              <input
                id="id_soyad"
                type="text"
                defaultValue={userData.soyadi}
                className={`form-control form-control-solid ${errors.soyadi ? 'is-invalid' : ''}`}
                {...register('soyadi', {
                  required: 'Bu alan zorunludur.',
                  validate: (value) => !!value.trim() || 'Bu alan zorunludur.',
                  minLength: { value: 3, message: 'Bu alanda en az 2 karakter olmalıdır.' },
                })}
                onBlur={(e) => {
                  if (e.target.value != e.target.value.trim()) {
                    const trimmedValue = e.target.value.trim();
                    setValue('soyadi', trimmedValue, { shouldValidate: true });
                    reset({
                      ...getValues(),
                      soyadi: trimmedValue,
                    });
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    if (e.target.value != e.target.value.trim()) {
                      const trimmedValue = e.target.value.trim();
                      setValue('soyadi', trimmedValue, { shouldValidate: true });
                      reset({
                        ...getValues(),
                        soyadi: trimmedValue,
                      });
                    }
                  }
                }}
              />
              {errors.soyadi && (
                <div className="invalid-feedback">{errors.soyadi.message}</div>
              )}
            </div>
          </div>
        </div>
      </form>
    </div>


  );
}

export default HesabimDegistirCard;
